import { useState, useEffect } from 'react'

function App() {

  const dataUrl = 'https://testdb.syukri-hadi.com/product-list'

  const [datas, setDatas] = useState([]);

  useEffect(() => {
    fetch(dataUrl)
      .then((res) => res.json())
      .then((data) => setDatas(data))
  }, [])

  console.log(datas)

  return (
    <div className="App">
      {datas.map(data => (
        <>
          test
          <p>Product Name: {data.product}</p>
          <p>Product Price: {data.price}</p>
        </>
      ))}
    </div>
  );
}

export default App;
